import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from 'react';
import EULA from './Components/EULA/eula';
import Policy from './Components/Privacy/pp';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
          <Route path="/" element={<EULA />}/>
          <Route path="/PrivacyPolicy" element={<Policy />} />      
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
