import React from 'react';

const EULA = () => {
    return (
        <div className='test'>
            
            <h1 align='middle' >UKnomi Terms of Use </h1>
            {/* 1 */}
            <div align='left' justify='center'>
                <h4>1. Scope</h4>
                <p>a.
This Agreement between You and uKnomi covers Your use of any uKnomi cloud services 
and hardware, including uKnomi Rewards and uKnomi Pay(“uKnomi Technology”). This 
Agreement incorporates any additional terms included in the applicable Schedule. 
Capitalized terms are defined in this document.  </p>
                <p className='test2'>i. 	You agree to be bound by this Agreement through:</p>
                <p>1. Your download, installation, or use of the uKnomi Technology; or</p>
                <p>2.	Your express agreement to this Agreement </p>
            </div>
            {/* 2 */}
            <div align='left' justify='center'>
                <h4>2. License </h4>
                <p>a.	uKnomi grants You a non-exclusive, non-transferable right to use the uKnomi Technology during the term granted in the applicable purchase agreement between uKnomi and its customer or as otherwise stated by uKnomi.  </p>
            </div>
            {/* 3 */}
            <div align='left' justify='center'>
                <h4>3. Additional Conditions of Use.
</h4>
                <p>a. Unless expressly agreed by uKnomi, You may not:</p>
                <p className='test2'>i.	Transfer, sell, sublicense, monetize or make the functionality of any uKnomi Technology available to any third party;</p>
<p className='test2'>ii.	Remove, modify, or conceal any product identification, copyright, proprietary, intellectual property notices, other marks; or</p>
<p>iii.	Reverse engineer, decompile, decrypt, disassemble, modify, or make derivative works of the uKnomi Technology.  </p>
            </div>
            {/* 4 */}
            <div align='left' justify='center'>
                <h4>4. Cloud Services</h4>
                <p>a. You will not intentionally </p>
                <p className='test2'>i.	Interfere with other customers’ access to, or use of, the uKnomi cloud services, including a denial-of-service attack, unauthorized access, penetration testing, crawling, or distribution of malware (including viruses, trojan horses, worms, time bombs, spyware, adware, and cancelbots); or</p>
<p className='test2'>ii.	Cause an unusual spike or increase in Your use of the cloud service that negatively affects operation of the cloud service or;
</p>
<p className='test2'>iii. Access the uKnomi Technology using screen scraping or bots. </p>
            </div>
            {/* 5 */}
            <div align='left' justify='center'>
                <h4>5. Protecting Account Access</h4>
                <p>a.	You will keep all account information up-to-date, use reasonable means to protect Your account information, passwords and other login credentials, and promptly notify uKnomi of any known or suspected unauthorized use or access to Your account. </p>
            </div>
            {/* 6 */}
            <div align='left' justify='center'>
                <h4>6. Use with third party products. </h4>
                <p>a.	UKnomi my provide links to third-party websites providing services that we may believe may be of interest to uKnomi users. Your use uKnomi with third party products, such use is at Your risk. You are responsible for reviewing and complying with any third-party provider terms, including its privacy policy. uKnomi does not provide support or guarantee ongoing integration support for products that are not a native part of uKnomi except as otherwise stated by uKnomi. uKnomi does not endorse, warrant or guarantee the products or services available through any such offers, and uKnomi is not an agent or broker or otherwise responsible for the activities or policies of those web sites. uKnomi does not guarantee that the third-party offer information displayed through the uKnomi Technology are actually the terms that may be offered to You if You pursue the offer or that they are the best terms, lowest rates available in the market, or otherwise suitable for You. You must complete Your own review and due diligence of any third-party offers, products or services, and uKnomi disclaims any express or implied obligation to do so.
</p>
            </div>
            {/* 7 */}
            <div align='left' justify='center'>
            <h4>7. Confidentiality </h4>
                <p>a. For the purposes of this Agreement, the following definitions apply:</p>
                <p className='test2'>i. Confidential Information means non-public proprietary information of the 
disclosing party (Discloser) obtained by the receiving party (“Recipient”) in 
connection with this Agreement. </p>
<p>b. Recipient will hold in confidence and use no less than reasonable care to avoid 
disclosure of any Confidential Information to any third party, except for its employees, 
affiliates, and contractors who have a need to know (“Permitted Recipients”). </p>
<p>c. Recipient:</p>
<p className='test2'>i. Must ensure its Permitted Recipients are subject to written confidentiality 
obligations no less restrictive than the Recipient’s obligations under this EULA; 
and</p>
<p className='test2'>ii. Is liable for any breach of this section by its Permitted Recipients. </p>
<p>d. Such nondisclosure obligations will not apply to information which:</p>
<p className='test2'>i. Is known by Recipient without confidentiality obligations;</p><p>ii. Is or has become public knowledge through no fault of Recipient; or</p>
<p className='test2'>iii. Is independently developed by Recipient. </p><p>e. Recipient may disclose Discloser’s Confidential Information if required under regulation, 
law, or court order provided that Recipient provides prior notice to Discloser (to the 
extent legally permissible). </p>
<p>f. Upon the reasonable request of Discloser, Recipient will either return, delete, or destroy 
all Confidential Information of Discloser and certify the same. </p>
            </div>
            {/* 8 */}
            <div align='left' justify='center'>
            <h4>8. How We Use Data</h4>
                <p>a. uKnomi will access, process, and use data in connection with Your use of uKnomi in 
accordance with applicable privacy and data protection laws and with any applicable 
uKnomi privacy policy. You can access the latest uKnomi privacy policy at 
<a href='https://uknomi.com/'>www.uknomi.com</a>.
</p>

            </div>
            {/* 9 */}
            <div align='left' justify='center'>
            <h4>9.	uKnomi Pay </h4>
                <p>a.	This section 9 applies to Your use of uKnomi Pay</p>
                <p className='test2'>i.	You authorize uKnomi to process payments  on your behalf for orders you  place with the merchant. </p>
                <p className='test2'>ii.	Requests for refunds for payments made with uKnomi Pay will be  processed according to the merchant’s refund policy. </p>
                <p className='test2'>iii.	For clarity, no goods may be shipped for orders placed with uKnomi Pay.  </p>
            </div>
            {/* 10 */}
            <div align='left' justify='center'>
            <h4>10. Ownership</h4>
                <p>a. Nothing in this Agreement transfers ownership in, or grants any license to, any 
intellectual property rights. You retain any ownership of Your content and uKnomi 
retains ownership of the uKnomi Technology and content, including all hardware and 
cloud services. </p>
            </div>
            {/* 11 */}
            <div align='left' justify='center'>
            <h4>11. Modifications</h4>
                <p>a.	You hereby assign to uKnomi Your entire right, title, and interest (including, without limitation, all patent rights, design rights, copyrights, and trade secrets) in any feedback, test results or modifications or improvements to the uKnomi Technology which You may propose or make or which You and uKnomi jointly make. To the extent such rights cannot be assigned, You hereby waive such rights as to uKnomi. </p>

            </div>
            {/* 12 */}
            <div align='left' justify='center'>
            <h4>12. Warranties</h4>
                <p>a. To the extent permitted by law, uKnomi expressly disclaims all warranties and 
conditions of any kind, express or implied, including without limitation any warranty, 
condition, or other implied terms as to merchantability, fitness for a particular purpose 
or non-infringement, or that the uKnomi Technology will be secure, uninterrupted or 
error-fee.</p>

            </div>
            {/* 13 */}
            <div align='left' justify='center'>
            <h4>13. Liability</h4>
                <p>a. Neither party will be liable for indirect, incidental, exemplary, special, or consequential 
damages; loss or corruption of data or interruption or loss of business; or loss of 
revenues, profits, goodwill, or anticipated sales or savings. </p>
                <p>b. The maximum aggregate liability of each party under this Agreement is limited to the 
fees received by uKnomi from You during the 12 month period immediately preceding 
the first event giving rise to such liability. </p>
<p>c. Sections 15.a and 15.b do not apply to liability arising from:</p>
<p className='test2'>i. Your failure to pay all amounts due; or</p>
<p className='test2'>ii. Your breach of Sections 2 (License) and 6 (Cloud Services). </p>
<p>d. This limitation of liability applies whether the claims are in warranty, contract, tort 
(including negligence), infringement, or otherwise, even if either party has been advised 
of the possibility of such damages. Nothing in this Agreement limits or excludes any 
liability that cannot be limited or excluded under applicable law. This limitation of 
liability is cumulative and not per incident.</p>
            </div>
            {/* 14 */}
            <div align='left' justify='center'>
            <h4>14. Termination and Suspension</h4>
                <p>a. uKnomi may immediately suspend your right to use the uKnomi Technology or 
terminate the Agreement if you breach Sections 2 (License), 6 (Cloud Services), and 10 
(Confidentiality). </p>
                <p>b. If a party materially breaches this Agreement and does not cure that breach within 30 
days after receipt of written notice of the beach, the non-breaching party may
terminate this Agreement for cause.</p>
<p>c. Upon termination of the Agreement, you must stop using the uKnomi Technology and 
destroy any copies of Confidential Information within Your control. You must promptly 
return any hardware provided to you by uKnomi at Your expense. </p>
<p>d. Upon termination uKnomi reserves the right to repossess any hardware it provides to 
you by any legal means available. </p>
            </div>
            {/* 15 */}
            <div align='left' justify='center'>
                    <h4>15. General Provisions</h4>
                    <p>a. Survival
</p>
<p className='test2'>i. Sections 7 (Confidentiality), 9 (Ownership), 11 (Warranties), 15 (Liability), 16 
(Termination and Suspension), and 17 (General Provisions).</p>
<p>b. Third Party Beneficiaries</p>
<p className='test2'>i. This Agreement does not grant any right or cause of action to any third party.</p>
<p>c. Assignment</p>
<p className='test2'>
i. Neither party may assign or novate this Agreement in whole or in part without 
the other party’s express written consent except uKnomi may assign or novate 
this Agreement in the event of reorganization, merger, acquisition, or sale of a 
party or its assets. In no event will an Assignment relieve a party of its 
obligations under this Agreement.</p>
<p>d. Compliance with Laws</p>
<p className='test2'>i. Each party will comply with all laws and regulations applicable to their 
respective obligations under the Agreement. </p>
<p>e. This Agreement, and any disputes arising from it, will be governed exclusively by the law 
and courts of Delaware. </p>
<p>f. Notice</p>
<p className='test2'>i. Any notice delivered by uKnomi to You under this Agreement will be delivered 
via email or regular mail. Notice to uKnomi should be sent P. O. Box 163, New 
Castle, DE 19720. </p>
<p>g. Force Majeure</p>
<p className='test2'>i. Neither party will be responsible for failure to perform its obligations due to an 
event or circumstances beyond its reasonable control.</p>
<p>h. No Waiver</p>
<p className='test2'>i. Failure by either party to enforce any right under this Agreement will not waive 
that right. </p>
<p className='test2'>i. Severability</p>
<p className='test2'>i. If any portion of this Agreement is not enforceable, it will not affect any other 
terms.</p>
            </div>
            {/*  */}

        </div>
    );
};

export default EULA;